import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '@app/shared/models/user.model';
import { BehaviorSubject, filter, Observable, switchMap, tap } from 'rxjs';
import { UserRoleEnum } from '@core/enums/user-role.enum';
import { SettingsService } from '@core/services/settings.service';
import { USER_DATA_STORAGE_KEY } from '@core/constants/other.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private _userData$ = new BehaviorSubject<null | UserModel>(null);
  private _initLoad = new BehaviorSubject<boolean>(true);

  constructor(
    private readonly _oidcSecurityService: OidcSecurityService,
    private readonly _httpClient: HttpClient,
    private readonly _settingsService: SettingsService,
  ) {
    const data = localStorage.getItem(USER_DATA_STORAGE_KEY);
    if (data) {
      this._userData$.next(JSON.parse(data));
    }

    this._userData$
      .subscribe((data) => localStorage.setItem(USER_DATA_STORAGE_KEY, JSON.stringify(data)));
  }

  public get userData$(): Observable<UserModel | null> {
    return this._userData$.asObservable();
  }

  public get initLoad$(): Observable<boolean> {
    return this._initLoad.asObservable();
  }

  public get isSuperAdmin(): boolean {
    return !!this._userData$.getValue()?.roles?.includes(UserRoleEnum.Admin);
  }

  public get hasGroupsForAdministration(): boolean {
    return !!this._userData$.getValue()?.hasGroupsForAdministration;
  }

  public logout(): void {
    this._oidcSecurityService.logoff()
      .subscribe();
  }

  public loadUserProfile(): void {
    this._oidcSecurityService.userData$
      .pipe(
        filter((fullUserData) => fullUserData.userData !== null),
        switchMap(() => this.fetchUserData()),
        switchMap(() => this._settingsService.loadSettings()),
      )
      .subscribe(() => this._initLoad.next(false));
  }

  public fetchUserData(): Observable<UserModel> {
    return this._httpClient.get<UserModel>(`users/me`)
      .pipe(tap((userData) => this._userData$.next(userData)));
  }

}
